import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
  },

  {
    path: "/promotions",
    name: "Promotions",
    component: () => import("../views/NewPromotions.vue"),
  },

  {
    path: "/maid-agency-promotion-2-in-1-deal",
    name: "MaidAgencyPromotionTwoinOneDeal",
    component: () => import("../views/PromotionTwoinOneDeal.vue"),
  },

  {
    path: "/maid-agency-promotion-3-in-1-deal",
    name: "MaidAgencyPromotionThreeinOneDeal",
    component: () => import("../views/PromotionThreeinOneDeal.vue"),
  },

  {
    path: "/maid-agency-promotion-5-in-1-deal",
    name: "MaidAgencyPromotionFiveinOneDeal",
    component: () => import("../views/PromotionFiveinOneDeal.vue"),
  },

  {
    path: "/maid-agency-promotion-6-in-1-deal",
    name: "MaidAgencyPromotionSixinOneDeal",
    component: () => import("../views/PromotionSixinOneDeal.vue"),
  },

  {
    path: "/become-a-partner",
    name: "Partner",
    component: () => import("../views/BecomeaPartner.vue"),
  },

  {
    path: "/features",
    name: "Features",
    component: () => import("../views/NewFeatures.vue"),
  },

  {
    path: "/maid-agency-software-pricing-singapore",
    name: "SingaporePricing",
    component: () => import("../views/SubscriptionPricing.vue"),
  },

  {
    path: "/maid-agency-software-pricing-malaysia",
    name: "MalaysiaPricing",
    component: () => import("../views/MalaysiaPricing.vue"),
  },

  {
    path: "/demo",
    name: "Demo",
    component: () => import("../views/BookDemo.vue"),
  },

  {
    path: "/contact-us",
    name: "Contact Us",
    component: () => import("../views/ContactUs.vue"),
  },

  {
    path: "/report-vulnerability",
    name: "Report Vulnerability",
    component: () => import("../views/ReportVulnerability.vue"),
  },

  {
    path: "/responsible-disclosure-policy",
    name: "Responsible Disclosure Policy",
    component: () => import("../views/ResponsibleDisclosurePolicy.vue"),
  },

  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },

  {
    path: "/maid-agency-integrations",
    name: "Maid Agency Integrations",
    component: () => import("../views/MaidAgencyIntegrations.vue"),
  },

  {
    path: "/maid-agency-certifications",
    name: "Maid Agency Certifications",
    component: () => import("../views/MaidAgencyCertifications.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name + " | Maid Agency Management System Directories";
  NProgress.configure({ showSpinner: false });
  NProgress.start();

  if (window.gtag) {
    window.gtag("config", "G-0BVXXBD3GN", {
      page_path: to.path,
    });
  }
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
